<template>
  <div>
    <v-container class="px-5">
      <v-card class="mx-auto" max-width="900" outlined color="transparent">
        <v-card-text>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn color="#9575CD" dark @click="isEnglish = !isEnglish">{{
                isEnglish == false ? 'English' : 'Korean'
              }}</v-btn>
            </v-col>
          </v-row>
          <div class="mt-10 text--primary text-h5">
            <span>Introduction</span>
          </div>
          <v-divider></v-divider>
          <br />
          <div class="text--primary" v-show="isEnglish == false">
            <p>
              <b>Korean Rainbow United</b> (한국명: ”캐나다연합교회 무지개
              연대”)는 캐나다연합교회에서 활동하고 있는 한인 퀴어와 엘라이,
              목회자와 평신도로 구성된 느슨한 연대 네트워크로서
              호모포비아적(이성애자 중심의 혐오적) 이데올로기를 바탕으로 한 성서
              읽기, 해석, 가르침으로 인해 발생하는 낙인 및 차별에 저항하는 한국
              내/외의 운동에 동참하며 목소리를 낸다.
            </p>
            <p>
              캐나다연합교회는 1988년 GC32에서 “캐나다연합교회가 성적 지향성과
              상관 없이 모든 이를 연합교회의 완전한 구성원으로서 환영하고, 모든
              구성원이 목회자로 안수 받을 수 있는 자격이 있음”을 선언하는 역사적
              결정을 하였고, 2019년 Affirm United와 UCC가 함께 발촉하고 2021년
              마무리 된 “Living Apology Project (<a
                href="https://www.iridesce.ca/"
                >https://www.iridesce.ca/</a
              >)”는 1988년의 역사적 결정을 이루기까지 눈물로 호소한 노력과 그
              이후의 진통 (예를 들면, 캐나다연합교회 내 수많은 지역 교회의
              분열과 개인과 공동체의 상처), 치유와 Affirm의 과정을 다시
              조명하며, 우리가 더 많은 지식과 우리 자신과 다른 사람들을 하나님의
              사랑, 가치, 환영으로 받아들이는 것을 이끌면서, 상호 변혁적인
              우정을 향한 길로 제안하였다. 슬프게도, 포용과 환대의 교회 공동체가
              되기 위한 캐나다연합교회의 1988년의 기록은 한국을 위시한 많은
              파트너 교회들에게는 과거가 아니라 현재 진행형의 투쟁이다.
            </p>
            <p>
              캐나다연합교회는 A New Creed에서 “우리는 혼자가 아니다”라는 첫
              절로 신앙을 고백한다. 글로벌 에큐메니즘과 파트너쉽의 정신에 따라
              우리 “캐나다연합교회 무지개 연대”는 편견과 몰이해, 혐오를 사랑과
              정의의 이름으로 포장하여 정죄와 차별, 배제를 일삼고 소중한
              성소수자들을 죽음이라는 선택지로 몰아 넣는 한국 내외의 흐름에
              맞서서, 한국의 “무지개예수”(<a href="http://www.rainbowyesu.org/">
                http://www.rainbowyesu.org/</a
              >) 처럼 성소수자와 연대하는 한국 내의 기독교 연맹에 힘을 실어주는
              활동을 하고자 한다.
            </p>
            <p>
              우리의 중요 사역은 <span>1)</span> 자료 번역 <span>2)</span> 영상
              제작 <span>3)</span> 육우당 추모기도회 참여 (매년 4월)
              <span>4)</span> 한국의 성소수자 신학생, 목후생, 목회자와 평신도의
              양성과 연대를 지원하는 것이 될 것이다.
              <b>우리는 결코 혼자가 아니다.</b>
            </p>
          </div>
          <div class="text--primary" v-show="isEnglish == true">
            <p>
              <b>Korean Rainbow United</b> is a network of United
              Church of Canada for Korean queer people and allies, lay and
              clergy and their co-creating relationship. Our mission is to unite
              our voice with the affirming Christian alliances in Korea and
              beyond, and join in the “RainbowYesu(RainbowJesus)” activism
              currently struggling in Korea to resist the discrimination, hate
              and stigma caused by homophobic/heterosexist scripture reading,
              interpretation and teaching.
            </p>
            <p>
              The United Church confesses “We are not alone” as the first verse
              in the New Creed. We are not alone, never have been, and never
              will be. In the spirit of global ecumenism and partnership, Korean
              Rainbow United will create a double rainbow of hope as the United
              Church members/friends and as Korean Rainbow queer people and
              allies, and struggle and fight together against oppressions,
              especially harmful theologies of the Christian Right, which are
              driving Sexual Minority members to their death and to the social
              cliff edges by condemning, discriminating and excluding them
              habitually and structurally, in the name of God’s love and
              justice, disguising prejudice, lack of understanding and hatred.
            </p>
            <p>
              We, Korean Rainbow United, will carry out the ministry of
              solidarity in six focused areas:
              <span>1)</span> Translation of relevant UCC resources to Korean
              <span>2)</span> Production of Korean-English bilingual videos that
              embrace and love LGBTQ+ and Two Spirit (2S)
              <span>3)</span> Korean-language forum/workshop on LGBTQ2S+ issues,
              queer theology, and human rights
              <span>4)</span> Affiliation/participation in activities with
              “RainbowYesu” (Ecumenical Christian Solidarity group for
              Supporting LGBTQ+ in Korea) <span>5)</span> Solidarity with
              Rainbow*queer theological students, ministry candidates, ministers
              and allies in Korea <span>6)</span> Providing an on-line safe
              space for conversation and support. <b>We are not alone.</b>
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Introduce',
  data() {
    return {
      isEnglish: false,
    };
  },
};
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
  color: #9575cd;
}
.circle-image {
  border-radius: 100%;
}
ul {
  list-style: none;
}
ul li::before {
  content: '\2022';
  color: #4caf50;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
* {
  line-height: 2;
}
</style>
